import Head from "next/head";
import React from "react";

export const HeadMeta = ({
    title,
    description,
    type,
    url,
    siteName,
    twitterUsername,
    image,
}) => (
    <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:site_name" content={siteName} />

        {image && (
            <>
                <meta property="og:image" content={image} />
                <meta name="twitter:image" content={image} />
            </>
        )}

        <meta name="twitter:site" content={twitterUsername} />
        <meta
            name="twitter:card"
            content={image ? "summary_large_image" : "summary"}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={url} />
        <link
            rel="icon"
            type="image/png"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}/favicon.png`}
        />
        <link
            rel="icon"
            type="image/png"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}/favicon16.png`}
            sizes="16x16"
        />
        <link
            rel="icon"
            type="image/png"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}/favicon32.png`}
            sizes="32x32"
        />
        <link
            rel="icon"
            type="image/png"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}/favicon.png`}
            sizes="96x96"
        />
    </Head>
);

HeadMeta.defaultProps = {
    type: "website",
    siteName: "Fork Club",
    twitterUsername: "@forkclubhq",
};
